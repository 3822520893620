<template>
  <div>
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <div v-else>
      <!-- <BaseHeader :title="$route.params.status"></BaseHeader> -->

      <div class="card mt-2 border-0 bg-white shadow">
        <div class="card-header header-elements-inline pb-0">
          <h2 class="card-title font-weight-black text-capitalize">
            {{ $route.params.status }}
          </h2>
          <div class="header-elements">
            <div class="col-4 col-sm-4">
              <router-link
                :to="{ name: 'PlaceOrder' }"
                class="btn btn-primary ml-auto"
                >Order Now</router-link
              >
            </div>
          </div>
        </div>
        <div class="card-body text-right my-1 py-0" style="">
          <!-- <form>
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Start typing to search">
                <span class="input-group-append">
                  <button class="btn btn-success" type="search">Search</button>
                </span>
              </div>
            </form> -->
          <div class="row">
            <div class="col-12 col-sm-8 text-left">
              <div
                v-if="isEditable && bulkActionIds.length > 0"
                class="d-flex flex-row align-items-center"
              >
                <!-- <input  type="checkbox" style="width: 22px !important" checked />  -->
                <span
                  class="badge"
                  style="
                    height: 22px;
                    text-align: center;
                    vertical-align: middle !important;
                    border: 1px solid rgb(32 150 243);
                    color: rgb(32 150 243);
                    background: rgb(32 150 243 / 21%);
                    width: 22px;
                    padding: 3px;
                  "
                >
                  <span class="icon-check"></span>
                </span>
                <label class="my-1 ml-1"
                  >{{ bulkActionIds.length }} Orders Selected</label
                >

                <button
                  @click="bulkAction('pay')"
                  class="btn btn-primary px-3 ml-2"
                >
                  Pay
                </button>
                <!-- <select
                  @change="bulkAction('pay')"
                  class="form-control ml-2 pr-2"
                  style="
                    max-width: 120px !important;
                    border-color: #c1c1c1 !important;
                    color: #2096f3;
                    font-weight: bold;
                  "
                >
                  <option>Action</option>
                  <option value="pay">Pay</option>
                </select> -->
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr class="text-left">
                <th v-if="isEditable">Bulk Action</th>
                <th>#</th>
                <th>Order Id</th>
                <th>Subject</th>
                <th>Topic</th>
                <th>Level</th>
                <th>Due</th>
                <th>Pages</th>
                <th>Charts</th>
                <th>Slides</th>
                <th>Price</th>
                <th class="text-center" style="width: 30px">
                  <i class="icon-menu-open2"></i>
                </th>
              </tr>
            </thead>
            <tbody v-if="orders.length > 0">
              <tr v-for="(order, index) in orders" :key="index + 'ords'">
                <td v-if="isEditable">
                  <input
                    v-if="order.id"
                    class="form-control ml-2"
                    v-model="bulkActionIds"
                    name="bulkActionIds"
                    :id="`bulkActionIds${order.id}`"
                    type="checkbox"
                    :value="order.id"
                    style="width: 18px !important; height: 18px !important"
                  />
                  <h3 class="my-0 font-weight-black" v-else>Total</h3>
                </td>
                <td>
                  <span v-if="order.id">
                    {{ index + 1 }}
                  </span>
                </td>
                <td class="text-left">
                  <router-link
                    :to="{ name: 'Order', params: { id: order.id } }"
                    >{{ order.id || "" }}</router-link
                  >
                </td>
                <td>{{ order.subject_area_text || "" }}</td>
                <td class="text-left is-title">
                  <router-link
                    class="is-title"
                    :to="{ name: 'Order', params: { id: order.id } }"
                    >{{ order.title || "" }}</router-link
                  >
                </td>
                <td>{{ order.academic_level_text || "" }}</td>
                <td>
                  <span
                    style="min-width: 50px !important"
                    :class="
                      order.urgency_text.past_due
                        ? 'text-danger'
                        : 'text-success'
                    "
                  >
                    {{ order.urgency_text.date || "" }}
                  </span>
                </td>
                <td>{{ order.pages || "" }}</td>
                <td>{{ order.charts || "" }}</td>
                <td>{{ order.slides || "" }}</td>
                <td>
                  <span class="font-weight-bold" v-if="order.id"
                    >${{ order.cost || "" }}</span
                  >
                  <h3 class="my-0 font-weight-black" v-else>
                    ${{ order.cost || "" }}
                  </h3>
                </td>
                <td class="text-center">
                  <div class="btn-group" v-if="order.id">
                    <router-link
                      class="btn btn-outline-primary btn-sm"
                      :to="{ name: 'Order', params: { id: order.id } }"
                      >View</router-link
                    >
                    <router-link
                      v-if="isEditable"
                      class="btn btn-primary btn-sm"
                      :to="{ name: 'payment', params: { id: order.id } }"
                      >Pay</router-link
                    >
                    <router-link
                      v-if="isEditable"
                      class="btn btn-outline-primary btn-sm"
                      :to="{ name: 'EditOrder', params: { id: order.id } }"
                      >Edit</router-link
                    >
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot v-else>
              <tr>
                <td colspan="11">
                  <strong
                    >No orders.
                    <router-link
                      :to="{ name: 'PlaceOrder' }"
                      class="btn btn-outline-primary ml-auto btn-sm"
                      >Order Now</router-link
                    ></strong
                  >
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div class="card-footer" v-if="orders.length > 0">
          <div class="row">
            <div class="col-12 col-sm-8">
              <div
                v-if="isEditable && bulkActionIds.length > 0"
                class="d-flex flex-row align-items-center"
              >
                <!-- <input  type="checkbox" style="width: 22px !important" checked />  -->
                <span
                  class="badge"
                  style="
                    height: 22px;
                    text-align: center;
                    vertical-align: middle !important;
                    border: 1px solid rgb(32 150 243);
                    color: rgb(32 150 243);
                    background: rgb(32 150 243 / 21%);
                    width: 22px;
                    padding: 3px;
                  "
                >
                  <span class="icon-check"></span>
                </span>
                <label class="my-1 ml-1"
                  >{{ bulkActionIds.length }} Orders Selected</label
                >

                <button
                  @click="bulkAction('pay')"
                  class="btn btn-primary px-3 ml-2"
                >
                  Pay
                </button>

                <!-- <select
                  @change="bulkAction"
                  class="form-control ml-2 pr-2"
                  style="
                    max-width: 120px !important;
                    border-color: #c1c1c1 !important;
                    color: #2096f3;
                    font-weight: bold;
                  "
                >
                  <option>Action</option>
                  <option value="pay">Pay</option>
                </select> -->
              </div>
            </div>
          </div>
          <br/>

          <p class="text-left">
            From {{ filters.from || "" }} to {{ filters.to || "" }} of
            {{ filters.total || "" }} orders
          </p>
          <ul class="pagination pagination-flat align-self-center">
            <li class="page-item" v-if="filters.prev_page_url">
              <a class="page-link" @click="navigate(filters.prev_page_url)"
                >← &nbsp; Prev</a
              >
            </li>
            <li class="page-item active">
              <a class="page-link">On Page {{ filters.current_page || "" }}</a>
            </li>
            <li class="page-item" v-if="filters.next_page_url">
              <a class="page-link" @click="navigate(filters.next_page_url)"
                >Next &nbsp; →</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "OrderListing",
  computed: {
    ...mapState("orders", ["orders", "filters"]),
    isEditable() {
      if (this.$route.params.status == "unpaid") {
        return true;
      } else return false;
    },
  },

  data() {
    return {
      loading: false,
      bulkActionIds: [],
    };
  },

  beforeRouteUpdate(to, from, next) {
    this.loading = true;
    let payload = to.params && to.params.status ? to.params.status : "editing";
    this._getOrders(payload)
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
    next();
  },

  mounted() {
    this.loading = true;
    // console.log(this.$route.params.status);
    this._getOrders(this.$route.params.status || "editing")
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
  },

  methods: {
    ...mapActions("orders", ["_getOrders", "_navigate"]),

    bulkAction(action) {
      if (action == "pay") {
        console.log("bulkActionIds", this.bulkActionIds);
        this.$router.push({
          name: "BulkCheckout",
          params: {
            ids: JSON.stringify(this.bulkActionIds),
          },
        });
      }
    },
    navigate(route) {
      this.loading = true;
      this._navigate(route)
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.is-title {
  max-height: 3px !important;
  overflow-y: hidden;
  line-height: 1.5;
  overflow: hidden;
}
td {
  padding: 2px !important;
}
</style>
